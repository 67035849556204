exports.components = {
  "component---src-components-mdx-page-js-content-file-path-home-runner-work-site-site-src-pages-bot-md": () => import("./../../../src/components/mdxPage.js?__contentFilePath=/home/runner/work/site/site/src/pages/bot.md" /* webpackChunkName: "component---src-components-mdx-page-js-content-file-path-home-runner-work-site-site-src-pages-bot-md" */),
  "component---src-components-mdx-page-js-content-file-path-home-runner-work-site-site-src-pages-faq-md": () => import("./../../../src/components/mdxPage.js?__contentFilePath=/home/runner/work/site/site/src/pages/faq.md" /* webpackChunkName: "component---src-components-mdx-page-js-content-file-path-home-runner-work-site-site-src-pages-faq-md" */),
  "component---src-components-mdx-page-js-content-file-path-home-runner-work-site-site-src-pages-idtga-rules-md": () => import("./../../../src/components/mdxPage.js?__contentFilePath=/home/runner/work/site/site/src/pages/idtga/rules.md" /* webpackChunkName: "component---src-components-mdx-page-js-content-file-path-home-runner-work-site-site-src-pages-idtga-rules-md" */),
  "component---src-components-mdx-page-js-content-file-path-home-runner-work-site-site-src-pages-legal-md": () => import("./../../../src/components/mdxPage.js?__contentFilePath=/home/runner/work/site/site/src/pages/legal.md" /* webpackChunkName: "component---src-components-mdx-page-js-content-file-path-home-runner-work-site-site-src-pages-legal-md" */),
  "component---src-components-mdx-page-js-content-file-path-home-runner-work-site-site-src-pages-staff-md": () => import("./../../../src/components/mdxPage.js?__contentFilePath=/home/runner/work/site/site/src/pages/staff.md" /* webpackChunkName: "component---src-components-mdx-page-js-content-file-path-home-runner-work-site-site-src-pages-staff-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-a-brief-hiatus-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/a-brief-hiatus.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-a-brief-hiatus-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-dial-in-2020-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/dial-in-2020.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-dial-in-2020-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-elo-version-3-0-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/elo-version-3.0.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-elo-version-3-0-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-fouryears-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/fouryears.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-fouryears-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-hello-world-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/hello-world.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-hello-world-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-25-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-25.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-25-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-25-review-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-25-review.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-25-review-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-26-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-26.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-26-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-29-review-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-29-review.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-29-review-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-30-review-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-30-review.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-30-review-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-31-review-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-31-review.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-31-review-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-34-discussions-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-34-discussions.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-34-discussions-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-35-discussions-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-35-discussions.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-35-discussions-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-36-dicussions-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-36-dicussions.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-36-dicussions-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-feb-2021-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-feb-2021.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-feb-2021-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-mar-2021-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-mar-2021.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-mar-2021-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-may-2021-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-may-2021.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-may-2021-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-sep-2021-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/idtga-sep-2021.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-idtga-sep-2021-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-july-update-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/july-update.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-july-update-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-privacy-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/privacy.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-privacy-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-prizepool-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/prizepool.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-prizepool-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-redesign-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/redesign.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-redesign-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-site-refresh-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/site-refresh.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-site-refresh-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-site-update-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/site-update.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-site-update-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-splatoon-3-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/splatoon-3.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-splatoon-3-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-the-games-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/The_Games.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-the-games-md" */),
  "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-weakest-link-tips-md": () => import("./../../../src/components/mdxPost.js?__contentFilePath=/home/runner/work/site/site/src/pages/posts/weakest-link-tips.md" /* webpackChunkName: "component---src-components-mdx-post-js-content-file-path-home-runner-work-site-site-src-pages-posts-weakest-link-tips-md" */),
  "component---src-components-redirect-js": () => import("./../../../src/components/Redirect.js" /* webpackChunkName: "component---src-components-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-idtga-index-js": () => import("./../../../src/pages/idtga/index.js" /* webpackChunkName: "component---src-pages-idtga-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-index-js": () => import("./../../../src/pages/posts/index.js" /* webpackChunkName: "component---src-pages-posts-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-profile-login-js": () => import("./../../../src/pages/profile/login.js" /* webpackChunkName: "component---src-pages-profile-login-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */)
}

